<template>
  <div class="container mt-4">
    <div class="row mt-4">
      <div class="col">
        <button v-if="freshUser === -1" class="btn btn-primary float-end" @click="addUser()">Lisa uus</button>
        <h2>Kontaktid</h2>
        <div class="accordion" id="userAccordion">
          <!-- Users list -->
          <div class="accordion-item" v-for="(user, index) in users" :key="index">
            <h2 class="accordion-header" :id="'heading-' + user.id">
              <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" :data-bs-target="'#user-' + user.id" aria-expanded="false" :aria-controls="'user-' + user.id">
                <!-- Contact groups -->
                <div class="float-end">
                  <span v-for="group in orderBy(user.groups_ids, 'group')" :key="group" class="badge me-1" :style="'background-color: ' + groupInfo(group).color + ';'">{{ groupInfo(group).short }}</span>
                </div>
                <!-- Contact active -->
                <!--<i class="fas fa-check text-success" v-if="user.active"></i>-->
                <!-- Contact inactive -->
                <!--<i class="fas fa-check text-secondary opacity-25" v-if="!user.active"></i>-->
                {{ user.name }}
                <span v-if="user.active && user.name !== undefined" class="opacity-50">
                <i class="fas fa-envelope me-2" v-if="user.email_active"></i>
                <i class="fas fa-sms" v-if="user.tel_active"></i>
              </span>
                <span class="badge bg-warning ms-2" v-if="user.unsaved">MUUDATUSED SALVESTAMATA</span>
                <h5 v-if="user.name === undefined">UUS KONTAKT</h5>
              </button>
            </h2>
            <div :id="'user-' + user.id" class="accordion-collapse collapse" :class="{'show': user.id === undefined}" :aria-labelledby="'heading-' + user.id" data-bs-parent="#userAccordion">
              <div class="accordion-body">
                <!-- Active -->
                <div class="row mb-2">
                  <div class="col">
                    <div class="alert" :class="{'alert-success': user.active, 'alert-warning': !user.active}" role="alert">
                      <div class="form-check form-switch">
                        <input class="form-check-input" type="checkbox" @change="setUnsaved(user.id)" role="switch" v-model="user.active" :id="'active-' + user.id">
                        <label class="form-check-label" :for="'active-' + user.id">
                          Aktiveeritud<br>
                          <small v-if="!user.active">Mitte-aktiivsele kontaktile ei saadeta alarmide kohta teavitusi</small>
                          <small v-if="user.active">Aktiivsele kontaktile saadetakse alarmide kohta teavitusi</small>
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
                <!-- Divider -->
                <hr>
                <!-- Name and description -->
                <div class="row">
                  <!-- Name -->
                  <div class="col-12 col-md-6 mb-2">
                    <label :for="'name-' + user.id" class="form-label">Nimi</label>
                    <input type="text" class="form-control" :id="'name-' + user.id" v-model="user.name" placeholder="Kontakti nimi" @change="setUnsaved(user.id)">
                  </div>
                  <!-- Name -->
                  <div class="col-12 col-md-6 mb-2">
                    <label :for="'desc-' + user.id" class="form-label">Kirjeldus</label>
                    <input type="text" class="form-control" :id="'desc-' + user.id" v-model="user.description" placeholder="Valikuline lisainfo" @change="setUnsaved(user.id)">
                  </div>
                </div>
                <!-- Contact details -->
                <div class="row">
                  <!-- Phone -->
                  <div class="col-12 col-md-6 mb-2">
                    <label :for="'phone-' + user.id" class="form-label">Telefoninumber</label>
                    <div class="input-group mb-3">
                      <input type="text" class="form-control" :id="'phone-' + user.id" v-model="user.tel_no" placeholder="Telefoninumber" @change="setUnsaved(user.id)">
                      <button class="btn btn-primary btn-sm" @click="testSend('sms', user.id)">Saada test SMS</button>
                    </div>
                  </div>
                  <!-- E-mail -->
                  <div class="col-12 col-md-6 mb-2">
                    <label :for="'email-' + user.id" class="form-label">E-mail</label>
                    <div class="input-group mb-3">
                      <input type="email" class="form-control" :id="'email-' + user.id" v-model="user.email" placeholder="E-maili aadress" @change="setUnsaved(user.id)">
                      <button class="btn btn-primary btn-sm" @click="testSend('email', user.id)">Saada test E-mail</button>
                    </div>
                  </div>
                </div>
                <!-- Notifications on/off -->
                <div class="row">
                  <!-- Phone active -->
                  <div class="col-12 col-md-6 mb-2">
                    <div class="form-check ms-1">
                      <input class="form-check-input" type="checkbox" @change="setUnsaved(user.id)" role="switch" v-model="user.tel_active" :value="user.tel_active" :id="'tel_active-' + user.id">
                      <label class="form-check-label" :for="'tel_active-' + user.id">Teavitused telefonile</label>
                    </div>
                  </div>
                  <!-- Email active -->
                  <div class="col-12 col-md-6 mb-2">
                    <div class="form-check ms-1">
                      <input class="form-check-input" type="checkbox" @change="setUnsaved(user.id)" role="switch" v-model="user.email_active" :value="user.emaill_active" :id="'email_active-' + user.id">
                      <label class="form-check-label" :for="'email_active-' + user.id">Teavitused e-mailile</label>
                    </div>
                  </div>
                </div>
                <!-- Divider -->
                <hr>
                <!-- User groups and working time -->
                <div class="row">
                  <!-- Groups -->
                  <div class="col-12 col-md-6 mb-2">
                    Grupid
                    <!-- Groups list -->
                    <div class="form-check" v-for="group in $store.state.groups" :key="group.id">
                      <input class="form-check-input" type="checkbox" @change="setUnsaved(user.id)" v-model="user.groups_ids" :value="group.id" :id="'group-' + user.id + '-' + group.id">
                      <label class="form-check-label" :for="'group-' + user.id + '-' + group.id">
                        <span class="badge" :style="'background-color: ' + group.color + ';'">{{ group.name }}</span>
                      </label>
                    </div>
                  </div>
                </div>
                <!-- Divider -->
                <hr>
                <!-- Save changes -->
                <div class="row mt-4">
                  <div class="col">
                    <div class="d-grid gap-2">
                      <!-- Editing existing contact -->
                      <div v-if="user.id !== undefined">
                        <button class="btn btn-outline-danger btn-sm" type="button" @click="removeUser(user.id)">Kustuta kontakt</button>
                        <button class="btn btn-primary btn-lg ms-2 float-end" type="button" :disabled="!user.unsaved" @click="saveUser(user.id)">
                          <span v-if="user.unsaved">Salvesta muudatused</span>
                          <span v-else>Salvestatud</span>
                        </button>
                      </div>
                      <!-- Editing new contact -->
                      <div v-if="user.id === undefined">
                        <button class="btn btn-outline-secondary btn-lg" type="button" @click="cancelAdd()">Tühista</button>
                        <button class="btn btn-primary btn-lg ms-2" type="button" :disabled="user.name === undefined || user.name === ''" @click="createUser">
                          Salvesta kontakt
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- End user loop -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import Vue2Filters from 'vue2-filters'

export default {
  mixins: [Vue2Filters.mixin],
  data () {
    return {
      users: []
    }
  },
  mounted () {
    this.getUsers()
  },
  methods: {
    // Set row as unsaved
    setUnsaved (id) {
      const userIndex = this.users.findIndex(obj => obj.id === id)
      this.$set(this.users[userIndex], 'unsaved', true)
    },
    // Return group info based on ID
    groupInfo (id) {
      const group = this.$store.state.groups.find(obj => obj.id === id)
      if (group === undefined) {
        return {}
      } else {
        return group
      }
    },
    // Send test SMS to contact
    testSend (type, contactId) {
      const endpoint = (type === 'sms') ? 'sendTestSms' : 'sendTestEmail'
      const _this = this
      const token = localStorage.getItem('localToken')
      axios.get(this.$store.state.eksekoApi + '/' + endpoint + '?contact_id=' + contactId, { headers: { Authorization: `${token}` } })
        .then((response) => {
          console.log(response.data)
          _this.$swal({ icon: 'success', title: 'Test ' + type + ' saadetud', showConfirmButton: false, timer: 1500 })
        })
        .catch(function (error) {
          console.log(error)
          _this.$swal({ icon: 'error', title: 'Tekkis viga', showConfirmButton: false, timer: 1500 })
        })
    },
    // Add new user to DB
    addUser () {
      this.users.unshift({ groups_ids: [], work_time: { days: [], end: {}, start: {} } })
    },
    // Get users from DB
    getUsers () {
      const token = localStorage.getItem('localToken')
      axios.get(this.$store.state.eksekoApi + '/getContactList', { headers: { Authorization: `${token}` } })
        .then((response) => {
          console.log(response.data)
          this.users = response.data
          // User worktime hack fix
          this.users.forEach((user) => {
            if (user.work_time === null) {
              console.log('Fixed work_time for ' + user.name)
              user.work_time = { days: [], start: {}, end: {} }
            }
          })
        })
        .catch(function (error) {
          console.log(error)
        })
    },
    // Save updated user in DB
    saveUser (id) {
      const _this = this
      const token = localStorage.getItem('localToken')
      axios.post(this.$store.state.eksekoApi + '/updateContact', this.users.find(obj => obj.id === id), { headers: { Authorization: `${token}` } })
        .then(function (response) {
          console.log(response.data)
          const userIndex = _this.users.findIndex(obj => obj.id === id)
          _this.users[userIndex].unsaved = false
          _this.$swal({ icon: 'success', title: 'Salvestatud', showConfirmButton: false, timer: 1500 })
        })
        .catch(function (error) {
          console.log(error)
          _this.$swal({ icon: 'error', title: 'Tekkis viga', showConfirmButton: false, timer: 1500 })
          _this.getUsers()
        })
    },
    // Delete user from DB
    removeUser (id) {
      const user = this.users.find(obj => obj.id === id)
      this.$swal({
        title: 'Oled kindel?',
        text: 'Kustuta "' + user.name + '" kontakt?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Jah, eemalda!',
        cancelButtonText: 'Katkesta'
      }).then((result) => {
        if (result.isConfirmed) {
          const _this = this
          const token = localStorage.getItem('localToken')
          axios.post(this.$store.state.eksekoApi + '/deleteContact', { id: id }, { headers: { Authorization: `${token}` } })
            .then(function (response) {
              _this.users = _this.users.filter(obj => obj.id !== id)
              _this.$swal('Valmis!', 'Kontakt "' + user.name + '" eemaldati', 'success')
            })
            .catch(function (error) {
              console.log(error)
              _this.$swal({ icon: 'error', title: 'Tekkis viga', showConfirmButton: false, timer: 1500 })
              _this.getUsers()
            })
        }
      })
    },
    // Create new user in DB
    createUser () {
      const _this = this
      const token = localStorage.getItem('localToken')
      axios.post(this.$store.state.eksekoApi + '/insertContact', this.users.find(obj => obj.id === undefined), { headers: { Authorization: `${token}` } })
        .then(function (response) {
          console.log(response.data)
          _this.users = _this.users.filter(obj => obj.id !== undefined)
          _this.$swal({ icon: 'success', title: 'Kontakt lisatud', showConfirmButton: false, timer: 1500 })
          _this.getUsers()
        })
        .catch(function (error) {
          console.log(error)
          _this.$swal({ icon: 'error', title: 'Tekkis viga', showConfirmButton: false, timer: 1500 })
          _this.getUsers()
        })
    },
    // Cancel adding new user
    cancelAdd () {
      this.users = this.users.filter(obj => obj.id !== undefined)
    }
  },
  computed: {
    // Find array index for fresh user that has not been saved
    freshUser: function () {
      return this.users.findIndex(obj => obj.id === undefined)
    }
  }
}
</script>
